import React, { FC } from "react";
import { ContentSection, TitleText } from "./appComponents";

export const PrivacyPolicy: FC = () => {
  return (
    <ContentSection>
      <TitleText>
        Polityka prywatności
        
      </TitleText>
    </ContentSection>
  );
};

import styled from "styled-components";

export const AppWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  font-size: 1.6rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0;
  background-color: #ffffff;
  position: relative;
`;

export const LogoLink = styled.a`
  display: flex;
`;
export const LogoSection = styled.div`
  width: 100%;
  padding: 20px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #202c42;
  align-items: center;
`;
export const ContentSection = styled.div`
  width: 100%;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const TitleText = styled.div`
  width: 100%;
  max-width: 960px;
  color: #202c42;
  font-size: 2.4rem;
  text-align: center;
`;

export const Logo = styled.img`
  width: 100%;
  max-width: 200px;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  padding: 8px 0;
`;

export const FooterLink = styled.a`
  padding: 0 8px;
  font-size: 1.4rem;
  color: #202c42;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const AppButton = styled.a`
  padding: 8px 16px;
  background-color: #21661f;
  color: #ffffff;
  border-radius: 20px;
  text-decoration: none;
  font-size: 1.6rem;
  line-height: 1.6rem;
  cursor: pointer;
`;

export const Header = styled.div`
  width: 100%;
  max-width: 96rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #202c42;
  align-items: center;
`;

export const Spacer = styled.div`
  height: 40px;
`;

import React, { FC } from "react";
import { ContentSection, TitleText } from "./appComponents";

export const Terms: FC = () => {
  return (
    <ContentSection>
      <TitleText>
       Regulamin
       </TitleText>
    </ContentSection>
  );
};

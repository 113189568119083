import React, { FC } from "react";
import { AppButton, ContentSection, Spacer, TitleText } from "./appComponents";

export const Home: FC = () => {
  return (
    <ContentSection>
      <TitleText>
        Narzędzie dla osób zajmujących się zarówno przygotowywaniem jak i
        nadzorem nad produkcją konstrukcji spawanych. 
      </TitleText>
      <Spacer/>
      <AppButton href="https://app.weldingapp.io">Przejdź do aplikacji</AppButton>
    </ContentSection>
  );
};

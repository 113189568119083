import React from "react";
import logo from "./weldingApp_logo_white.svg";
import "./App.css";
import {
  AppWrapper,
  Logo,
  LogoSection,
  Footer,
  FooterLink,
  LogoLink,
  AppButton,
  Header,
} from "./components/appComponents";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Home } from "./components/Home";
import { Terms } from "./components/Terms";
import { PrivacyPolicy } from "./components/Policy";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <Home />,
    },
    {
      path: "/terms",
      element: <Terms />,
    },
    {
      path: "/privacyPolicy",
      element: <PrivacyPolicy />,
    },
  ]);

  return (
    <AppWrapper>
      <LogoSection>
        <Header>
        <LogoLink href="/">
          <Logo src={logo} />
        </LogoLink>
        <AppButton href="https://app.weldingapp.io">Zaloguj się</AppButton>

        </Header>
      </LogoSection>
      <RouterProvider router={router} />

      <Footer>
        <FooterLink href="/terms">Regulamin</FooterLink>
        <FooterLink href="/privacyPolicy">Polityka prywatności</FooterLink>
      </Footer>
    </AppWrapper>
  );
}

export default App;
